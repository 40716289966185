<template>
  <div v-if="!isExistingUser" class="signUp">
    <div class="staticContent">
      <div class="image">
        <v-img src="/assets/img/signup-image.svg" alt />
      </div>
    </div>

    <div :class="`signUpForm ${signedUp ? 'signedUp' : ''}`">
      <div v-if="signedUp" class="registered">
        <div class="image">
          <v-img
            src="/assets/img/feather_check-circle.svg"
            alt
            max-width="60px"
            max-height="60px"
          />
        </div>
        <p>{{ signUpHeading }}</p>

        <div v-html="getMessage"></div>
      </div>
      <v-form v-else ref="form" class="vForm" lazy-validation>
        <div class="registrationHeading">
          <h1>
            <span>Sign up to Prezent Enterprise</span>
          </h1>
        </div>
        <div class="label">First name</div>
        <v-text-field
          v-model="firstName"
          :label="firstName ? '' : 'Enter your first name'"
          :rules="formRules.firstName"
          :disabled="isReadonlyfirstName"
          required
          single-line
          color="#000"
          class="mb-4"
          id="first"
        />
        <div class="label">Last name</div>
        <v-text-field
          v-model="lastName"
          :label="lastName ? '' : 'Enter your last name'"
          :rules="formRules.lastName"
          :disabled="isReadonlylastName"
          required
          single-line
          color="#000"
          class="mb-4"
          id="last"
        />
        <div class="label">Email</div>
        <v-text-field
          type="email"
          v-model="email"
          :label="email ? '' : 'Enter your work email'"
          :rules="formRules.email"
          :disabled="isReadonlyemail"
          required
          single-line
          color="#000"
          class="mb-4"
          id="email"
          @blur="handleEmailChange"
        />
        <div class="label">Company name</div>
        <v-text-field
          v-model="companyName"
          :label="companyName ? '' : 'Enter your company name'"
          :rules="formRules.companyName"
          required
          single-line
          color="#000"
          class="mb-4"
          id="companyName"
        />
        <div class="terms">
          <p>
            By clicking below, you agree to our<br /><a
              href="https://prezent.ai/terms-of-service/"
              target="_blank"
            >
              Terms of Service </a
            >and
            <a href="https://prezent.ai/privacy-policy/" target="_blank"
              >Privacy Policy</a
            >.
          </p>
        </div>
        <v-btn
          @click="submit"
          :disabled="!firstName || !lastName || !isValidEmail || !companyName"
          color="primary"
          rounded
          min-width="144"
          height="50"
          id="submit"
          :loading="isSignupLoading"
        >
          Continue
        </v-btn>
      </v-form>
    </div>
  </div>
  <Redirect v-else />
</template>

<script>
import { isEmojiExist, isValidEmail } from '../../../components/utils';
import CustomRegistrationApi from '../../../API/custom-registration-api';
import Redirect from './Redirect.vue';
import { capitalizeFirstLetter } from '../../../utils/common';

export default {
  name: 'SignUp',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      formRules: {
        firstName: [
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => !!v || this.$t('warnings.firstNameRequired'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        lastName: [
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => !!v || this.$t('warnings.lastNameRequired'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        email: [(v) => isValidEmail(v) || this.$t('warnings.emailMustBeValid')],
        companyName: [
          (v) => !!v || this.$t('warnings.companyNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
      },
      isReadonlyfirstName: false,
      isReadonlylastName: false,
      isReadonlyemail: false,
      isSignupLoading: false,
      signedUp: false,
      isExistingUser: false,
      signUpHeading: 'Thank you for registering!',
      isWaitListCompany: false,
      enterpriseDefaultTeamExist: false,
      counter: 5,
    };
  },
  components: {
    Redirect,
  },
  methods: {
    submit() {
      this.isSignupLoading = true;
      const payload = [
        {
          email: this.email?.toLowerCase(),
          firstname: this.firstName,
          lastname: this.lastName,
          companyName: this.companyName,
        },
      ];

      CustomRegistrationApi.methods
        .register(payload)
        .then((resp) => {
          if (!resp.error) {
            console.log(resp);
            this.signedUp = true;
          } else {
            this.enterpriseDefaultTeamExist =
              resp?.data[0]?.enterpriseDefaultTeamExist;
            this.isExistingUser = resp?.data[0]?.userAlreadyExist;
            this.isWaitListCompany = resp?.data[0]?.isWaitListCompany;
            if (this.isExistingUser) {
              setTimeout(() => {
                this.$router.push('/signin', () => {});
              }, 3000);
            }
            if (this.isWaitListCompany) {
              this.signedUp = true;
              this.signUpHeading = 'Thank you for your interest in Prezent!';
            }
            if (this.enterpriseDefaultTeamExist) {
              this.startCounter();
              this.signedUp = true;
              this.signUpHeading =
                'Yay! Your organization already has an Enterprise subscription with Prezent';
            }
          }
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            console.log('error');
          } else if (error?.response?.status === 500) {
            this.isWaitListCompany = true;
            this.signedUp = true;
            this.signUpHeading = 'Thank you for your interest in Prezent!';
          }
        })
        .finally(() => {
          this.isSignupLoading = false;
        });
    },
    handleEmailChange() {
      if (this.email.includes('@') && this.email.includes('.')) {
        const companyDomain = this.email.split('@')[1];
        const cNameArray = companyDomain.split('.');
        const cName = cNameArray && cNameArray.length ? cNameArray[0] : '';
        this.companyName = capitalizeFirstLetter(cName);
      } else {
        this.companyName = '';
      }
    },
    startCounter() {
      const countDownTimer = setInterval(() => {
        this.counter -= 1;
        if (this.counter < 1) {
          clearInterval(countDownTimer);
          this.$router.push('/signin', () => {});
        }
      }, 1000);
    },
  },
  computed: {
    isValidEmail() {
      return isValidEmail(this.email);
    },
    getMessage() {
      if (this.isWaitListCompany) {
        return '<p>We have added you to our waitlist <br /> and will get back to you soon.</p>';
      }
      if (this.enterpriseDefaultTeamExist) {
        return `<p>You can use your corporate email id and password to log in to Prezent<br /> <br />  Redirecting you to the login page in ${this.counter}s...</p>`;
      }
      return '<p>We have emailed you a link.<br /> Please use it to complete the <br /> sign-up process and begin <br /> using Prezent.</p>';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.signUp {
  display: flex;
  justify-content: space-evenly;

  .signedUp {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: none !important;
    width: 40% !important;
  }

  .signUpForm {
    margin: 0px 50px;
    max-width: 300px;
    width: 25%;

    button {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      margin-top: 38px;
      text-align: center;
      text-transform: none;
    }

    .label {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
    }

    .registered {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 24px;
      text-align: center;
      .image {
        margin-bottom: 10px;
        margin-top: 40px;
      }
    }

    .terms {
      margin-top: 20px;

      p {
        margin-bottom: 0px;
      }
      a {
        text-decoration: none;
      }
    }
    .vForm {
      .registrationHeading {
        margin-bottom: 25px;
        padding-top: 10px;
        h1 {
          width: 400px;
        }
      }
    }
  }

  .staticContent {
    .heading {
      color: #000000;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 38px;
      margin-bottom: 16px;
    }

    .subHeading {
      color: #000000;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}
</style>
