<template>
  <v-app class="application-main">
    <v-main>
      <div class="registration-wrapper">
        <div class="logo">
          <a href="https://prezent.ai">
            <v-img
              src="/assets/img/prezent_logo.svg"
              alt
              width="200px"
              height="44px"
            />
          </a>
        </div>
        <div v-if="!isExistingUser">
          <div
            :class="`registration-content  ${
              innerWidth < 500 ? 'mobile-content' : ''
            }`"
          >
            <div class="registration-content-left" v-if="innerWidth > 500">
              <v-img src="/assets/img/signup-image.svg" alt />
            </div>
            <div
              v-if="!isUserRegistered"
              :class="`registration-content-right ${
                innerWidth < 500 ? 'mobile-right' : ''
              }`"
            >
              <v-form
                class="registration-form"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <div class="registration-heading">
                  <h1>
                    <span>Sign up to Prezent</span>
                  </h1>
                </div>
                <div class="label">First name</div>
                <v-text-field
                  v-model="firstName"
                  :label="firstName ? '' : 'Enter your first name'"
                  :rules="formRules.firstName"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="first"
                  :loading="loadingWithExternalFpTestId"
                />
                <div class="label">Last name</div>
                <v-text-field
                  v-model="lastName"
                  :label="lastName ? '' : 'Enter your last name'"
                  :rules="formRules.lastName"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="last"
                  :loading="loadingWithExternalFpTestId"
                />
                <div class="label">Phone</div>
                <v-text-field
                  v-model="phone"
                  :label="phone ? '' : 'Enter your phone number'"
                  :rules="formRules.phone"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="phone"
                  :loading="loadingWithExternalFpTestId"
                />
                <div class="label">Work email</div>
                <v-text-field
                  v-model="email"
                  :label="email ? '' : 'john.doe@company.com'"
                  :rules="formRules.email"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="email"
                  @blur="handleAudienceEmailChange"
                  :loading="loadingWithExternalFpTestId"
                />
                <div class="label">Company name</div>
                <v-text-field
                  v-model="companyName"
                  :label="companyName ? '' : 'Enter your company name'"
                  :rules="formRules.companyName"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="companyName"
                  :loading="loadingWithExternalFpTestId"
                />
                <!-- <div class="label">Job Title</div>
                <v-text-field
                  v-model="jobTitle"
                  :label="jobTitle ? '' : 'Enter your job title'"
                  :rules="formRules.jobTitle"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="jobTitle"
                />-->
                <div class="label">Country</div>
                <v-autocomplete
                  v-model="country"
                  :label="country ? '' : 'Select country'"
                  :rules="formRules.country"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="country"
                  item-text="name"
                  :items="countries"
                  return-object
                  :loading="loadingWithExternalFpTestId"
                />
                <!-- <div class="label">Job Title</div>
                <div class="label">State/Province</div>
                <v-autocomplete
                  v-model="state"
                  :label="state ? '' : 'Select state or province'"
                  :rules="formRules.state"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="state"
                  item-text="name"
                  :items="states"
                  return-object
                />
                <div class="label">What Presentation software do you use?</div>
                <v-select
                  v-model="prezentationSoftware"
                  :label="prezentationSoftware ? '' : 'Select one'"
                  :rules="formRules.prezentationSoftware"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="prezentationSoftware"
                  :items="prezentationSoftwares"
                /> -->
                <div class="terms">
                  <p>
                    By clicking below, you agree to our<br /><a
                      href="https://prezent.ai/terms-of-service/"
                      target="_blank"
                    >
                      Terms of Service </a
                    >and
                    <a href="https://prezent.ai/privacy-policy/" target="_blank"
                      >Privacy Policy</a
                    >.
                  </p>
                </div>
                <div style="text-align: left">
                  <v-btn
                    @click="submit"
                    :disabled="
                      !firstName ||
                      !lastName ||
                      !email ||
                      !companyName ||
                      !phone ||
                      !country ||
                      country === ''
                    "
                    color="primary"
                    rounded
                    min-width="144"
                    height="50"
                    id="submit"
                    :loading="isLoading"
                  >
                    Continue
                  </v-btn>
                  <p>No credit card required</p>
                  <div v-if="error !== ''" class="error">
                    {{ error }}
                  </div>
                </div>
              </v-form>
            </div>
            <div v-else class="confirmation-wrapper">
              <div class="confirmation">
                <v-img
                  src="/assets/img/feather_check-circle.svg"
                  max-width="60px"
                  max-height="60px"
                  alt
                />
                <div v-html="getMessage"></div>
                <template v-if="isWaitListCompany">
                  <clip-loader
                    v-if="loginLoading"
                    :loading="loginLoading"
                    :color="`#21a7e0`"
                    :width="'60'"
                    :height="'60'"
                    :size="'50px'"
                    class="cliploader"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isExistingUser" class="existing-user">
          <div>
            <v-img
              src="/assets/img/account-already-exists.svg"
              alt
              max-width="300px"
              max-height="300px"
            />
            <p>Account already exists</p>
            <p>Redirecting you to login...</p>
            <clip-loader
              v-if="loginLoading"
              :loading="loginLoading"
              :color="`#21a7e0`"
              :width="'60'"
              :height="'60'"
              :size="'50px'"
              class="cliploader"
            />
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import TrialApi from '../API/trial-api';
import { getExternalFingerprintResultByTestId } from '../utils/api-helper';
import { isEmojiExist } from '../components/utils';
import { capitalizeFirstLetter } from '../utils/common';
import { CountriesNowService } from '../services/CountriesNowService';

export default {
  name: 'TrialRegistration',
  components: {
    ClipLoader,
  },
  data() {
    return {
      isWaitListCompany: false,
      isUserRegistered: false,
      isExistingUser: false,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
      jobTitle: '',
      country: '',
      state: '',
      prezentationSoftware: '',
      formRules: {
        firstName: [
          (v) => !!v || this.$t('warnings.firstNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        lastName: [
          (v) => !!v || this.$t('warnings.lastNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        email: [
          (v) => !!v || this.$t('warnings.emailRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) =>
            (v &&
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v,
              )) ||
            this.$t('warnings.emailMustBeValid'),
        ],
        companyName: [
          (v) => !!v || this.$t('warnings.companyNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        phone: [
          (v) => !!v || this.$t('warnings.phoneNumberRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        jobTitle: [
          (v) => !!v || this.$t('warnings.jobTitleRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        prezentationSoftware: [
          (v) => !!v || this.$t('warnings.presentationSoftwareRequired'),
        ],
        country: [(v) => !!v || this.$t('warnings.countryRequired')],
        state: [(v) => !!v || this.$t('warnings.stateRequired')],
      },
      prezentationSoftwares: [
        'Microsoft Powerpoint',
        'Google Slides',
        'Microsoft 365',
        'Keynote',
        'Other',
      ],
      valid: true,
      isLoading: false,
      error: '',
      loginLoading: true,
      countries: [],
      states: [],
      countDown: 0,
      innerWidth: 700,
      loadingWithExternalFpTestId: false,
      queryString: '',
    };
  },
  mixins: [TrialApi],
  computed: {
    ...mapGetters('users', ['getCompanyDomain']),
    ...mapState('users', ['currentUser']),
    getMessage() {
      if (!this.isWaitListCompany) {
        return `<p>Thank you for your interest!<br /></p>
              <p>
                We have emailed you a link.<br />Please use it to complete the<br />sign-up process and begin<br />using Prezent
              </p>`;
      }
      if (this.isWaitListCompany) {
        return `
          <p>Thank you for your interest in Prezent!</p><p>We have added you to our waitlist<br />and will get back to you soon.</p>
          <p>Redirecting you to our website in ${this.countDown}s...</p>
        `;
      }
      return '';
    },
  },
  methods: {
    loadCountries() {
      CountriesNowService.getCountries().then((countries) => {
        this.countries = countries;
      });
    },
    loadStates(iso2) {
      CountriesNowService.getStates(iso2).then((states) => {
        if (states.length) {
          this.states = states;
        } else {
          this.states = [{ name: 'NA' }];
          this.state = 'NA';
        }
      });
    },
    isEmailDomainSame(v) {
      if (v) {
        const domain = this.currentUser.user.id.split('@')[1];
        const colleagueDomain = v.split('@')[1];
        return domain === colleagueDomain;
      }
      return true;
    },
    handleAudienceEmailChange() {
      if (this.email.includes('@') && this.email.includes('.')) {
        const companyDomain = this.email.split('@')[1];
        const cNameArray = companyDomain.split('.');
        const cName = cNameArray && cNameArray.length ? cNameArray[0] : '';
        this.companyName = capitalizeFirstLetter(cName);
        this.email = this.email.toLowerCase();
      } else {
        this.companyName = '';
      }
    },
    async submit() {
      try {
        console.log('in submit method');
        this.isLoading = true;
        const payload = [
          {
            email: this.email.toLowerCase(),
            firstname: this.firstName,
            lastname: this.lastName,
            companyname: this.companyName,
            phone: this.phone,
            jobTitle: this.jobTitle,
            country: this.country.name,
            state: this.state.name,
            prezentationSoftware: this.prezentationSoftware,
            queryString: this.queryString,
          },
        ];
        if (this.$route.query.utm_campaign) {
          payload[0].source = this.$route.query.utm_campaign;
        }

        console.log('payload', payload);
        await TrialApi.methods
          .trialRegistration(payload)
          .then((resp) => {
            console.log('resp', resp);
            const isWaitListCompany = resp?.data[0]?.isWaitListCompany;

            this.isUserRegistered = true;
            this.isExistingUser = resp?.data[0]?.userAlreadyExist;
            this.isLoading = false;
            if (this.isExistingUser) {
              setTimeout(() => {
                this.queryString = '';
                this.$router.push('/signin', () => {});
              }, 3000);
            }
            if (isWaitListCompany) {
              this.queryString = '';
              this.startWebsiteRedirectCounter();
            }
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
            if (err?.response?.status === 422) {
              this.isExistingUser =
                err?.response?.data?.data[0]?.userAlreadyExist;
              const isWaitListCompany =
                err?.response?.data?.data[0]?.isWaitListCompany;
              this.isUserRegistered = true;
              if (this.isExistingUser) {
                setTimeout(() => {
                  this.queryString = '';
                  this.$router.push('/signin', () => {});
                }, 3000);
              }
              if (isWaitListCompany) {
                this.queryString = '';
                this.startWebsiteRedirectCounter();
              }
            } else if (err?.response?.status === 400) {
              console.log(err);
              this.error = 'Email must be valid';
            }
          });
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },

    startWebsiteRedirectCounter() {
      this.countDown = 5;
      this.isWaitListCompany = true;

      const countDownTimer = setInterval(() => {
        if (this.countDown <= 1) {
          clearInterval(countDownTimer);
          window.location.href = 'https://www.prezent.ai/thank-you';
        } else {
          this.countDown -= 1;
        }
      }, 1000);
    },
  },
  async mounted() {
    console.log(window.innerWidth);
    this.innerWidth = window.innerWidth;
    if (this.$route.query.waitList) {
      this.isUserRegistered = this.$route.query.waitList;
      this.isWaitListCompany = this.$route.query.waitList;
      this.startWebsiteRedirectCounter();
    }
    if (
      this.$route.query.type &&
      this.$route.query.type === 'external-fingerprints'
    ) {
      // const { fname, lname, phone, email, company, country } =
      //   this.$route.query;
      this.loadingWithExternalFpTestId = true;
      await getExternalFingerprintResultByTestId(this.$route.query.test_id)
        .then((resp) => {
          this.firstName = resp?.data?.firstName || '';
          this.lastName = resp?.data?.lastName || '';
          this.phone = resp?.data?.phone || '';
          this.email = resp?.data?.email || '';
          this.country =
            resp?.data?.isd && resp?.data?.isd !== ''
              ? `${resp.data.isd.split('__')[0]}`
              : '';
          this.companyName = resp?.data?.company || '';
        })
        .catch((err) => {
          console.log(err);
        });
      this.loadingWithExternalFpTestId = false;
    }
    this.loadCountries();

    // getting query params for each trial registration
    const queries = this.$route?.query || {};
    const queriesMap = Object.keys(queries);
    if (queriesMap.length > 0) {
      let queryStr = '?';
      queriesMap.forEach((item, i) => {
        queryStr += `${i === 0 ? '' : '&'}${item}=${queries[item]}`;
      });
      this.queryString = queryStr;
    }
  },
  /* watch: {
    country(country) {
      this.loadStates(country.Iso2);
    },
  }, */
};
</script>

<style lang="scss" scoped>
.application-main {
  background: linear-gradient(
    0.61deg,
    rgba(149, 208, 234, 0.6) -22.84%,
    rgba(255, 255, 255, 0.048) 38.59%
  );
}
.registration-wrapper {
  height: fit-content;
  margin: auto;
  padding: 2rem;
  width: 100%;

  p {
    font-size: 14px;
  }
  .logo {
    width: fit-content;
  }
  .registration-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .registration-content-right {
      margin: 0px 50px;
      max-width: 300px;
      width: 25%;
      .registration-heading {
        margin-bottom: 25px;
        padding-top: 10px;
      }
      .registration-form {
        p {
          margin-top: 20px;
        }
        .label {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
        }
        button {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 38px;
          text-align: center;
          text-transform: none;
        }
        .error {
          background-color: transparent !important;
          border-color: transparent !important;
          color: red;
        }
      }
    }
    .confirmation-wrapper {
      align-items: center;
      display: flex;
      font-size: 24px;
      justify-content: center;
      margin-bottom: 50px;
      text-align: center;
      width: 40%;
      .confirmation {
        .v-image {
          margin: 0 auto;
          margin-bottom: 10px;
        }
      }
    }
  }
  .existing-user {
    display: flex;
    justify-content: center;
    padding: 25px;
    text-align: center;
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.mobile-right {
  width: 100% !important;
  margin: 15px !important;
  justify-content: flex-start !important;
}

.mobile-content {
  justify-content: flex-start !important;

  .confirmation-wrapper {
    width: 100% !important;
    margin-top: 20px;
  }
}

.terms {
  p {
    margin-bottom: 0px;
  }
  a {
    text-decoration: none;
  }
}
.registration-content-left {
  margin-top: 40px;
}
</style>
