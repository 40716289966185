<template>
  <v-app>
    <v-main>
      <div class="reset-password-wrapper">
        <v-form ref="resetPasswordForm" @submit.prevent="onSubmit">
          <v-card class="reset-password-card elevation-0">
            <div class="logo">
              <img
                src="assets/img/prezent_stack.svg"
                alt
                max-width="350px"
                min-height="44px"
              />
            </div>
            <v-card-title class="mb-3 pl-0">
              <span class="headline">Password Reset</span>
            </v-card-title>
            <v-card-subtitle
              v-if="!resendVerificationCodeClicked && emailVerificationCodeSent"
              class="pl-0"
            >
              <img src="/assets/img/verification_code_sent.svg" alt />
              <span class="sub-title">Verification Code Emailed</span>
            </v-card-subtitle>
            <v-card-subtitle v-if="resendVerificationCodeClicked" class="pl-0">
              <v-progress-circular indeterminate color="primary" />
              <span class="sub-title">Emailing verification code</span>
            </v-card-subtitle>
            <v-card-text class="pl-0">
              <v-container class="pa-0">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <div class="label">Username</div>
                    <v-text-field
                      label=""
                      :rules="usernameRules"
                      v-model="username"
                      color="#000"
                      id="username"
                      @blur="handleUsernameChange"
                      :disabled="true"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-form ref="verifyCodeControl">
                      <div class="label">Code</div>
                      <v-text-field
                        label="Enter verification code"
                        :rules="verificationCodeRules"
                        v-model="verificationCode"
                        color="#000"
                      />
                    </v-form>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <div class="label">New password</div>
                    <v-form ref="passwordControl">
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="passwordRules"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Create a new password"
                        single-line
                        @click:append="show1 = !show1"
                        color="#000"
                      />
                    </v-form>
                  </v-col>
                  <div
                    v-if="password && passwordRequirements.length"
                    class="password-errors-box"
                  >
                    <div
                      v-for="(value, index) in passwordErrors"
                      :key="index"
                      class="password-wrapper"
                    >
                      <span :class="checkError(value) ? 'visibility' : ''"
                        >&#10003;</span
                      >
                      <p :class="checkError(value) ? 'password-error' : ''">
                        {{ value }}
                      </p>
                    </div>
                  </div>
                  <v-col cols="12" sm="12" md="12">
                    <div class="label">Confirm new password</div>
                    <v-form ref="confirmPasswordControl">
                      <v-text-field
                        v-model="confirmPassword"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="confirmPasswordRules"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confirm new password"
                        single-line
                        @click:append="show2 = !show2"
                        color="#000"
                      />
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="pa-0">
              <div class="mb-2 actions pr-4">
                <v-btn
                  rounded
                  type="submit"
                  color="primary"
                  min-width="333"
                  height="50"
                  :loading="isSendingResetLink"
                  :disabled="
                    !verificationCode ||
                    !password ||
                    !confirmPassword ||
                    ((!password ||
                      !confirmPassword ||
                      !(password && password.length >= 8) ||
                      !(confirmPassword === password)) &&
                      !ssoUser) ||
                    !!passwordRequirements.length
                  "
                  class="mb-4"
                  id="reset-password-btn"
                >
                  Reset Password
                </v-btn>
                <div
                  class="resend-code-btn"
                  style="cursor: pointer"
                  @click="resendVerificationCode"
                >
                  <p
                    :class="{ 'disable-text': resendVerificationCodeClicked }"
                    class="primary--text text-center"
                  >
                    Resend Verification Code
                  </p>
                </div>
              </div>
            </v-card-actions>
            <div class="try-for-free">
              Don’t have an account?&nbsp;
              <a :href="getTryForFreeLink()" @click="tryForFreeClick()"
                >Try for free</a
              >
            </div>
          </v-card>
          <div v-if="error" class="error mb-4">
            {{ error }}
          </div>
        </v-form>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { Auth } from 'aws-amplify';
import { isEmojiExist } from '../components/utils';
import { trackGeneralEvents } from '../components/common/Analytics/GeneralEvents';
import {
  TD_PLACEMENT,
  TD_USER,
  NA,
} from '../components/common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ResetPassword',
  props: {
    msg: String,
  },
  data() {
    return {
      valid: true,
      error: '',
      username: '',
      usernameRules: [
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        (v) => !!v || 'Username is required',
      ],
      verificationCode: '',
      verificationCodeRules: [(v) => !!v || 'Verification code is required'],
      show1: false,
      show2: false,
      password: '',
      confirmPassword: '',
      passwordErrors: [
        '8 characters',
        '1 letter',
        '1 number',
        '1 special character',
      ],
      passwordRules: [
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        () => this.isPasswordMatched('password') || 'Password must match',
      ],
      confirmPasswordRules: [
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        () =>
          this.isPasswordMatched('confirmPassword') || 'Password must match',
      ],
      isSent: false,
      isSendingResetLink: false,
      resendVerificationCodeClicked: false,
      emailVerificationCodeSent: true,
    };
  },
  computed: {
    passwordRequirements() {
      const errors = [];
      if (this.password && this.password.length < 8)
        errors.push('8 characters');
      if (this.password && !/[A-Za-z]{1}/.test(this.password))
        errors.push('1 letter');
      if (this.password && !/\d/.test(this.password)) errors.push('1 number');
      if (this.password && !/[^A-Za-z0-9]/.test(this.password))
        errors.push('1 special character');
      return errors;
    },
  },
  methods: {
    tryForFreeClick() {
      trackGeneralEvents.generalTryForFreeClick(
        {},
        {
          [TD_USER]: this.username.length ? this.username : NA,
          [TD_PLACEMENT]: 'Reset password with code',
        },
      );
    },
    checkError(err) {
      return this.passwordRequirements.includes(err);
    },
    getTryForFreeLink() {
      const appDomain = process.env.VUE_APP_DOMAIN;
      return `${appDomain}/trialregistration`;
    },
    handleUsernameChange() {
      this.username = this.username.toLowerCase();
    },
    handleClose() {
      this.$modal.hide('ResetPassword');
    },
    isPasswordMatched(control) {
      if (
        (control === 'password' && this.confirmPassword) ||
        (control === 'confirmPassword' && this.password)
      ) {
        return this.password === this.confirmPassword;
      }
      return true;
    },
    async onSubmit() {
      // if (!this.$refs.resetPasswordForm.validate()) return;
      try {
        this.isSendingResetLink = true;
        this.username = `${this.username.trim()}`;
        Auth.forgotPasswordSubmit(
          this.username,
          this.verificationCode,
          this.password,
        )
          .then((data) => {
            this.isSendingResetLink = false;
            this.error = '';
            this.isSent = data;
            this.$router.push('/signin');
            this.handleClose();
          })
          .catch((err) => {
            this.isSendingResetLink = false;
            console.log(err);
            this.error = err.message;
          });
      } catch (err) {
        this.isSendingResetLink = false;
        console.log(err);
      }
    },
    async resendVerificationCode() {
      this.resendVerificationCodeClicked = true;
      this.verificationCode = '';
      this.$refs.verifyCodeControl.resetValidation();
      this.username = `${this.username.trim()}`;
      Auth.forgotPassword(this.username)
        .then((data) => {
          this.resendVerificationCodeClicked = false;
          console.log(data);
          this.error = '';
        })
        .catch((err) => {
          this.resendVerificationCodeClicked = false;
          console.error(err);
          this.error = err.message;
        });
    },
  },
  mounted() {
    if (this.$route.query.username) {
      this.username = this.$route.query.username.toLowerCase();
    }
  },
  watch: {
    confirmPassword(value) {
      if (value) {
        this.$refs.passwordControl.resetValidation();
        this.$refs.passwordControl.validate();
      }
    },
    password(value) {
      if (value) {
        this.$refs.confirmPasswordControl.resetValidation();
        this.$refs.confirmPasswordControl.validate();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
::v-deep .v-main__wrap {
  margin: auto;
}
.reset-password-wrapper {
  height: fit-content;
  margin: auto;
  width: 28.25rem;
  .logo {
    margin-bottom: 1.25rem;
  }
  .reset-password-card {
    padding: 3.125rem;
    position: relative;

    .label {
      color: black;
      font-size: 1.25rem;
      font-weight: normal;
      text-align: left;
    }

    .v-card__title,
    .v-card__subtitle {
      text-align: left;
    }

    .v-card__subtitle {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      span {
        color: #21a7e0;
        margin-top: 0.625rem;
      }
    }

    .headline {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .sub-title {
      font-size: 1.25rem;
      font-weight: normal;
    }
    .v-card__actions {
      justify-content: center;

      button {
        font-size: 1.5rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
      }

      a {
        text-decoration: none;
      }

      .disable-text {
        pointer-events: none !important;
        color: rgba(0, 0, 0, 0.12) !important;
        pointer-events: none !important;
      }
    }
  }
  .error {
    background-color: transparent !important;
    border-color: transparent !important;
    color: red;
    text-align: center;
  }
}
.v-progress-circular {
  margin-right: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.resend-code-btn p {
  font-size: 1rem;
}
.password-errors-box {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  font-size: 14px;
  left: -25%;
  padding: 5px;
  position: absolute;
  top: 55%;
  .password-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    .visibility {
      visibility: hidden;
    }
    span {
      font-size: 18px;
      font-weight: 500;
    }
    .password-error {
      color: red;
    }
    p {
      margin-bottom: 0px;
    }
  }
}
.password-errors-box::after {
  border: 10px solid transparent;
  border-left: 10px solid white;
  clear: both;
  content: '';
  position: absolute;
  right: -20px;
  visibility: visible;
  top: 40px;
}
.try-for-free {
  text-align: center;
  color: $gray-dark-login-text;
  display: block;
}
</style>
