<template>
  <v-app>
    <v-main>
      <div class="signin-wrapper">
        <div class="logo">
          <img
            src="/assets/img/prezent_stack.svg"
            alt
            width="350px"
            height="44px"
          />
        </div>
        <EnterpriseSignupEmailConfirmation
          v-if="enterpriseSignupEmailSent"
          @onBackToLogin="reset"
        />
        <v-form
          v-else
          class="signin-form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent=""
        >
          <v-card class="signin-custom-card" v-if="!continueClicked">
            <div>
              <div class="label">Email</div>
              <v-text-field
                v-model="username"
                :rules="emailRules"
                label="Enter your work email"
                required
                single-line
                color="#000"
                class="mb-4"
                id="username"
                :placeholder="!autofilled ? 'Enter your work email' : ''"
                :disabled="usernameDisabled"
                v-on:keyup.enter="onContinue"
                validate-on-blur
              />
            </div>
            <div class="terms">
              <div>By clicking below, you agree to our</div>
              <div>
                <a href="https://prezent.ai/terms-of-service/" target="_blank"
                  >Terms of Service</a
                >
                <p>&nbsp;and&nbsp;</p>
                <a href="https://prezent.ai/privacy-policy/" target="_blank"
                  >Privacy Policy</a
                >.
              </div>
            </div>
            <v-btn
              @click="onContinue"
              :disabled="!emailValid"
              color="primary"
              rounded
              height="50"
              id="continue"
              :loading="continueLoading"
            >
              Continue
            </v-btn>
            <p class="reset-password d-flex justify-center align-center mt-4">
              Trouble logging in?&nbsp;
              <span style="display: flex" v-if="!ssoConfig.isSSOApp">
                <router-link to="/forgot-password">
                  <p id="reset-password-route-link" @click="handleResetClick">
                    Reset password&nbsp;
                  </p>
                </router-link>
                or&nbsp;
              </span>
              <a
                @click="hanldeGetInTouchClick"
                href="mailto:success@prezent.ai?subject=Trouble logging in"
              >
                Get in touch
              </a>
            </p>
            <div class="try-for-free">
              <div>
                <p>Don’t have an account?&nbsp;</p>
                <a :href="getTryForFreeLink()" @click="tryForFreeClick()"
                  >Try for free</a
                >
              </div>
            </div>
          </v-card>
          <v-card class="signin-custom-card" v-if="continueClicked">
            <div>
              <div class="label">Email</div>
              <v-text-field
                v-model="username"
                :rules="emailRules"
                label="Enter your work email"
                required
                single-line
                color="#000"
                class="mb-4"
                id="username"
                v-on:keyup.enter="submit"
              />
            </div>
            <div>
              <div class="label">Password</div>
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[
                  passwordRules.required,
                  passwordRules.min,
                  passwordRules.noEmoji,
                ]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Enter password"
                id="password"
                single-line
                @click:append="show1 = !show1"
                :placeholder="!autofilled ? 'Enter password' : ''"
                v-on:keyup.enter="submit"
              />
            </div>
            <div class="terms">
              <div>By clicking below, you agree to our</div>
              <div>
                <a href="https://prezent.ai/terms-of-service/" target="_blank"
                  >Terms of Service</a
                >
                <p>&nbsp;and&nbsp;</p>
                <a href="https://prezent.ai/privacy-policy/" target="_blank"
                  >Privacy Policy</a
                >.
              </div>
            </div>
            <v-btn
              @click="submit"
              :disabled="!username || !password"
              color="primary"
              rounded
              width="144"
              height="50"
              id="submit"
              class="email-login-button"
              :loading="!isUserLoggedIn && loginClicked"
            >
              Log in
            </v-btn>
            <p class="reset-password d-flex justify-center align-center mt-4">
              Trouble logging in?&nbsp;
              <span style="display: flex" v-if="!ssoConfig.isSSOApp">
                <router-link to="/forgot-password">
                  <p id="reset-password-route-link" @click="handleResetClick">
                    Reset password&nbsp;
                  </p>
                </router-link>
                or&nbsp;
              </span>
              <a
                @click="hanldeGetInTouchClick"
                href="mailto:success@prezent.ai?subject=Trouble logging in"
              >
                Get in touch
              </a>
            </p>
            <div class="try-for-free">
              <div>
                <p>Don’t have an account?&nbsp;</p>
                <a :href="getTryForFreeLink()" @click="tryForFreeClick()"
                  >Try for free</a
                >
              </div>
            </div>
          </v-card>
          <div v-if="error !== ''" class="error">
            {{ error }}
          </div>
        </v-form>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { Auth } from 'aws-amplify';
import { AmplifyEventBus } from 'aws-amplify-vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import PrivacyPolicyModal from '../components/common/Profile/PrivacyPolicy.vue';
import EnterpriseSignupEmailConfirmation from './components/EnterpriseSignup/EnterpriseSignupEmailConfirmation.vue';
import { SSOConfig } from '../runtimeConfig';
import {
  trySSOLoginAndRedirect,
  cacheUser,
  getCachedUser,
  clearCachedUser,
} from '../utils/common';
import { isEmojiExist } from '../components/utils';
import { trackGeneralEvents } from '../components/common/Analytics/GeneralEvents';
import {
  TD_USER,
  TD_PLACEMENT,
  NA,
  TD_TIMESTAMP,
} from '../components/common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SignInCustom',
  props: {
    msg: String,
  },
  components: {
    EnterpriseSignupEmailConfirmation,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['isUserLoggedIn']),
    emailValid() {
      return (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.username,
        ) &&
        this.username.length <= 320 &&
        this.username.indexOf('@') < 64 &&
        this.username.length - this.username.indexOf('@') < 255
      );
    },
  },
  watch: {
    username() {
      this.continueClicked = false;
      this.password = '';
    },
  },
  data() {
    return {
      continueLoading: false,
      continueClicked: false,
      autofilled: false,
      dirtyPwd: false,
      loginClicked: false,
      valid: true,
      error: '',
      username: '',
      usernameDisabled: false,
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        (v) =>
          (v &&
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            )) ||
          'Email must be valid',
        (v) => v.length <= 320 || 'Email must be valid', // total length should be max 320
        (v) => v.indexOf('@') < 64 || 'Email must be valid', // user part should be max 64
        (v) => v.length - v.indexOf('@') < 255 || 'Email must be valid', // domain part should be max 255
      ],
      show1: false,
      password: '',
      passwordRules: {
        required: (value) => !!value || 'Password is required',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => "The email and password you entered don't match",
        noEmoji: (v) => !isEmojiExist(v) || 'Emojis are not allowed',
      },
      ssoConfig: SSOConfig,
      enterpriseSignupEmailSent: false,
    };
  },
  methods: {
    // handleUsernameChange() {
    //   this.username = this.username.toLowerCase();
    // },
    ...mapActions('users', ['resetState']),
    handleResetClick() {
      trackGeneralEvents.generalResetPasswordClick(
        {},
        {
          [TD_USER]: this.username.length ? this.username : NA,
          [TD_TIMESTAMP]: new Date()
            .toUTCString()
            .replaceAll(',', '')
            .replace('GMT', ''),
        },
      );
    },
    hanldeGetInTouchClick() {
      trackGeneralEvents.generalGetInTouch(
        {},
        {
          [TD_USER]: this.username.length ? this.username : NA,
          [TD_TIMESTAMP]: new Date()
            .toUTCString()
            .replaceAll(',', '')
            .replace('GMT', ''),
        },
      );
    },
    tryForFreeClick() {
      const placement = this.continueClicked
        ? 'Website Login (email and password)'
        : 'Website Login (email only)';
      trackGeneralEvents.generalTryForFreeClick(
        {},
        {
          [TD_USER]: this.username.length ? this.username : NA,
          [TD_PLACEMENT]: placement,
        },
      );
    },
    async onContinue() {
      this.error = '';
      if (this.$refs.form.validate()) {
        // check if sso user and redirect, else get password
        const email = this.username.trim().toLowerCase();
        this.username = email;
        this.continueLoading = true;
        const { ssoUser, enterpriseSignupEmailSent } =
          await trySSOLoginAndRedirect(email);
        if (enterpriseSignupEmailSent) {
          this.enterpriseSignupEmailSent = true;
        } else if (!ssoUser) {
          // This flag will ask for password to continue login
          this.continueClicked = true;
          // Keep loading false only if normal user
          this.continueLoading = false;
        }
      }
    },
    async submit() {
      this.error = '';
      if (this.$refs.form.validate()) {
        this.username = `${this.username.trim()}`;
        this.loginClicked = true;
        Auth.signIn(this.username, this.password)
          .then((user) => {
            cacheUser(user.attributes.email);
            this.error = '';
            AmplifyEventBus.$emit('authState', 'signedIn');
          })
          .catch((err) => {
            this.loginClicked = false;
            console.log(err);
            if (err.message.toLowerCase().includes('disabled')) {
              this.error = `Oops! User is disabled. Please contact your team admin.`;
            } else {
              this.error = err.message;
            }
          });
      }
    },

    showPrivacyModal() {
      this.$modal.show(PrivacyPolicyModal, {
        name: 'PrivacyPolicyModal',
        maxwidth: '300px',
        maxheight: '173px',
      });
    },

    reset() {
      this.enterpriseSignupEmailSent = false;
      this.continueClicked = false;
      this.continueLoading = false;
      this.username = '';
    },
    getTryForFreeLink() {
      const appDomain = process.env.VUE_APP_DOMAIN;
      return `${appDomain}/trialregistration`;
    },
  },
  mounted() {
    // Following logic is to check if the password field is prefilled by browser,
    // so that label is not shown (checks 10 times for now)
    let times = 0;
    const interval = setInterval(() => {
      times += 1;
      if (this.dirtyPwd || times === 10) {
        clearInterval(interval);
      }
      this.dirtyPwd = document.querySelector(
        'input[type="password"]:-webkit-autofill',
      );
      if (this.dirtyPwd) {
        this.autofilled = true;
      }
    }, 300);
  },
  async beforeMount() {
    const email = getCachedUser();
    try {
      clearCachedUser();
      this.resetState();
      Auth.signOut();
    } catch (err) {
      console.log(err);
    }
    if (email) {
      const { errorCode } = this.$route.params;
      if (errorCode && errorCode === '10001') {
        // This error code exist when the user is redirected to sigin when unauthorised
        this.error = 'Oops! Your session expired. Redirecting to login.';
      }
      this.username = email;
      this.continueLoading = true;
      this.usernameDisabled = true;
      const { ssoUser } = await trySSOLoginAndRedirect(email);
      if (!ssoUser) {
        // This flag will ask for password to continue login
        this.continueClicked = true;
        // Keep loading false only if normal user
        this.continueLoading = false;
        this.usernameDisabled = false;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';

::v-deep .v-main__wrap {
  margin: auto;
  margin-top: 2% !important;
}
.sso-login-button.sso-login-button {
  text-decoration: none;
  margin: 0 auto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none;
}
.email-login-button {
  margin-top: 38px;
}
.signin-custom-card {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  padding: 20px 50px 0px 50px;
  text-align: center;

  .label {
    text-align: left;
    font-size: 20px;
    font-weight: normal;
    color: black;
  }

  .v-card__title,
  .v-card__subtitle {
    text-align: left;
  }

  .headline {
    font-size: 24px;
    font-weight: bold;
    margin-left: -15px;
  }

  .sub-title {
    font-size: 20px;
    font-weight: normal;
  }
  .v-card__actions {
    justify-content: center;
    text-align: center;

    button {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: none;
    }

    a {
      text-decoration: none;
    }
  }
}
.signin-wrapper {
  width: 452px;
  height: fit-content;
  margin: auto;
  margin-top: 5px;
  text-align: center;

  .logo {
    margin-bottom: 0px !important;
  }

  .signin-form {
    .label {
      text-align: left;
    }
    button {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      margin: 0 auto;
      text-align: center;
      text-transform: none;
    }
    .email-login-button.email-login-button {
      margin-top: 28px;
    }
    .sso-login {
      color: $zen-blue-default;
      cursor: pointer;
      font-size: 16px;
      font-weight: normal;
    }

    .reset-password {
      font-size: 14px;
      font-weight: normal;
      color: $gray-dark-login-text;
      margin-bottom: -10px;
      width: 100%;

      #reset-password-route-link {
        margin-bottom: 0px;
      }

      a {
        text-decoration: none;
      }
    }
    .error {
      background-color: transparent !important;
      border-color: transparent !important;
      color: red;
    }
  }

  .terms {
    align-items: center;
    color: $gray-dark-login-text;
    display: block;
    margin: 20px;

    p {
      display: inline-flex;
      margin: 0;
      pre {
        font-family: inherit;
      }
    }
  }
  .try-for-free {
    align-items: center;
    color: $gray-dark-login-text;
    display: block;
    margin: 18px;

    p {
      display: inline-flex;
      margin: 0;
      pre {
        font-family: inherit;
      }
    }
  }
}
</style>
