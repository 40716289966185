<template>
  <div class="premiumAndStandardRegistrationFormContainer">
    <div class="header">
      <a href="https://prezent.ai">
        <v-img
          src="/assets/img/prezent_logo.svg"
          alt
          width="200px"
          height="44px"
        />
      </a>
    </div>
    <div class="redirect" v-if="accountExists">
      <v-img
        src="/assets/img/account-already-exists.svg"
        alt
        max-width="300px"
        max-height="300px"
      />
      <p class="heading">Account already exists</p>
      <p class="subHeading">Redirecting you to login...</p>
      <clip-loader
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
    </div>
    <div class="errorCases" v-else-if="registrationErrors">
      <div class="thanksContainerImageWrapper">
        <img src="/assets/img/survey/expired-link.svg" alt />
      </div>
      <div class="thanksContainerHeading">
        <h3 class="response">
          {{ failureMsg }}
        </h3>
        <h3 class="response">
          {{ 'Need help? You can reach us at ' }}
          <span class="successMaill">{{ 'success@prezent.ai' }}</span>
        </h3>
      </div>
    </div>
    <div class="signUp" v-else>
      <div class="staticContent">
        <div class="image">
          <v-img src="/assets/img/signup-image.svg" alt />
        </div>
      </div>
      <div :class="`${triedRegistration ? 'triedRegistration' : 'signUpForm'}`">
        <div v-if="triedRegistration" class="registered">
          <div class="image">
            <v-img
              src="/assets/img/feather_check-circle.svg"
              alt
              max-width="60px"
              max-height="60px"
            />
          </div>
          <p class="heading">{{ signUpHeading }}</p>

          <div class="subText" v-html="getMessage"></div>
        </div>
        <v-form v-else ref="form" class="vForm" lazy-validation>
          <div class="registrationHeading">
            <h1>
              <span>{{ getHeading }}</span>
            </h1>
          </div>
          <div class="label">First name</div>
          <v-text-field
            v-model="firstName"
            :label="firstName ? '' : 'Enter your first name'"
            :rules="formRules.firstName"
            required
            single-line
            color="#000"
            class="mb-4"
            id="first"
          />
          <div class="label">Last name</div>
          <v-text-field
            v-model="lastName"
            :label="lastName ? '' : 'Enter your last name'"
            :rules="formRules.lastName"
            required
            single-line
            color="#000"
            class="mb-4"
            id="last"
          />
          <div class="label">Email</div>
          <v-text-field
            type="email"
            v-model="email"
            :label="email ? '' : 'Enter your work email'"
            :rules="formRules.email"
            required
            single-line
            color="#000"
            class="mb-4"
            id="email"
            @blur="handleEmailChange"
          />
          <div class="label">Company name</div>
          <v-text-field
            v-model="companyName"
            :label="companyName ? '' : 'Enter your company name'"
            :rules="formRules.companyName"
            required
            single-line
            color="#000"
            class="mb-4"
            id="companyName"
          />
          <div class="terms">
            <p>
              By clicking below, you agree to our<br /><a
                href="https://prezent.ai/terms-of-service/"
                target="_blank"
              >
                Terms of Service </a
              >and
              <a href="https://prezent.ai/privacy-policy/" target="_blank"
                >Privacy Policy</a
              >.
            </p>
          </div>
          <v-btn
            @click="submit"
            :disabled="!firstName || !lastName || !isValidEmail || !companyName"
            rounded
            min-width="144"
            height="50"
            id="submit"
            class="submitBtn"
            :loading="isSignupLoading"
          >
            Continue
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { isEmojiExist, isValidEmail } from '../components/utils';
import { capitalizeFirstLetter } from '../utils/common';
import { registerBusinessPremiumOrStandardPlan } from '../API/custom-registration-api';

export default {
  name: 'PremiumOrStandardRegistration',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      formRules: {
        firstName: [
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => !!v || this.$t('warnings.firstNameRequired'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        lastName: [
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => !!v || this.$t('warnings.lastNameRequired'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        email: [(v) => isValidEmail(v) || this.$t('warnings.emailMustBeValid')],
        companyName: [
          (v) => !!v || this.$t('warnings.companyNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
      },
      isSignupLoading: false,
      accountExists: false,
      triedRegistration: false,
      signUpHeading: 'Thank you for registering!',
      isWaitListCompany: false,
      enterpriseDefaultTeamExist: false,
      counter: 5,
      failureMsg: 'Something went wrong!',
      registrationErrors: false,
    };
  },
  components: {
    ClipLoader,
  },
  methods: {
    async submit() {
      this.isSignupLoading = true;
      const payload = {
        email: this.email?.toLowerCase(),
        firstName: this.firstName,
        lastName: this.lastName,
        companyName: this.companyName,
      };

      await registerBusinessPremiumOrStandardPlan(
        payload,
        this.registrationType,
      )
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.userAlreadyExist &&
            resp.data.userAlreadyExist === true
          ) {
            this.accountExists = true;
            setTimeout(() => {
              this.$router.push('/signin', () => {});
            }, 3000);
          } else if (
            resp &&
            resp.data &&
            resp.data.enterpriseDefaultTeamExist &&
            resp.data.enterpriseDefaultTeamExist === true
          ) {
            this.enterpriseDefaultTeamExist = true;
            this.startCounter('signin');
            this.signUpHeading =
              'Yay! Your organization already has an Enterprise subscription with Prezent';
          } else if (
            resp &&
            resp.data &&
            resp.data.isWaitListCompany &&
            resp.data.isWaitListCompany === true
          ) {
            this.isWaitListCompany = true;
            this.startCounter('mainWebsite');
            this.signUpHeading = 'Thank you for your interest in Prezent!';
          } else {
            this.signUpHeading = 'Thank you for your registering!';
          }
          this.triedRegistration = true;
        })
        .catch((error) => {
          this.registrationErrors = true;
          this.failureMsg =
            error?.response?.data?.log || 'Something went wrong!';
        })
        .finally(() => {
          this.isSignupLoading = false;
        });
    },
    handleEmailChange() {
      if (this.email.includes('@') && this.email.includes('.')) {
        const companyDomain = this.email.split('@')[1];
        const cNameArray = companyDomain.split('.');
        const cName = cNameArray && cNameArray.length ? cNameArray[0] : '';
        this.companyName = capitalizeFirstLetter(cName);
      } else {
        this.companyName = '';
      }
    },
    startCounter(redirectLocation) {
      const countDownTimer = setInterval(() => {
        this.counter -= 1;
        if (this.counter < 1) {
          clearInterval(countDownTimer);
          if (redirectLocation && redirectLocation === 'mainWebsite') {
            window.location.href = 'https://prezent.ai';
          } else if (redirectLocation && redirectLocation === 'signin') {
            this.$router.push('/signin', () => {});
          }
        }
      }, 1000);
    },
  },
  computed: {
    getHeading() {
      return this.registrationType === 'premium'
        ? 'Sign up to Prezent Premium'
        : 'Sign up to Prezent Standard';
    },
    registrationType() {
      return this.$route.name === 'premium-registration'
        ? 'premium'
        : 'standard';
    },
    isValidEmail() {
      return isValidEmail(this.email);
    },
    getMessage() {
      if (this.enterpriseDefaultTeamExist) {
        return `<p>You can use your corporate email id and password to log in to Prezent<br /> <br />  Redirecting you to the login page in ${this.counter}s...</p>`;
      }
      if (this.isWaitListCompany) {
        return `<p>We have added you to our waitlist <br /> and will get back to you soon.</p><br />  Redirecting you to the main website in ${this.counter}s...</p>`;
      }
      return '<p>We have emailed you a link.<br /> Please use it to complete the <br /> sign-up process and begin <br /> using Prezent.</p>';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.premiumAndStandardRegistrationFormContainer {
  overflow: auto;
  padding: 1rem 2rem;
  .header {
    // padding: 0px 0px 10px 0px;
  }
  .errorCases {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    margin: 100px 0px 0px 0px;
    .response {
      text-align: center;
      margin: 0px 0px 8px 0px;
    }
    .successMaill {
      color: #21a7e0;
    }
  }
  .redirect {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      font-size: 16px;
      font-weight: 500;
    }
    .cliploader {
      margin: 32px 0px 0px 0px;
    }
  }
  .signUp {
    display: flex;
    justify-content: space-evenly;

    .triedRegistration {
      display: flex;
      justify-content: center;
      max-width: none !important;
      width: 40% !important;
      .registered {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 24px;
        text-align: center;
        .image {
          margin-bottom: 10px;
          margin-top: 40px;
        }
        .heading {
          margin-bottom: 16px;
          line-height: 36px;
        }
        .subText {
          line-height: 36px;
          margin-bottom: 16px;
        }
      }
    }

    .signUpForm {
      margin: 0px 50px;
      max-width: 300px;
      width: 25%;

      button {
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        letter-spacing: normal;
        line-height: normal;
        margin-top: 38px;
        text-align: center;
        text-transform: none;
      }

      .label {
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
      }

      .registered {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 24px;
        text-align: center;
        .image {
          margin-bottom: 10px;
          margin-top: 40px;
        }
        .heading {
          margin-bottom: 16px;
          line-height: 36px;
        }
        .subText {
          line-height: 36px;
          margin-bottom: 16px;
        }
      }

      .terms {
        margin-top: 20px;

        p {
          margin-bottom: 0px;
        }
        a {
          text-decoration: none;
          color: #21a7e0;
        }
      }
      .vForm {
        .registrationHeading {
          margin-bottom: 25px;
          padding-top: 10px;
          line-height: 42px !important;
          h1 {
            width: 400px;
          }
        }
        ::v-deep .v-input {
          padding-top: 12px;
          margin-top: 4px;
          margin-bottom: 16px !important;
          .v-input__control {
            .v-text-field__details {
              .v-messages {
                .v-messages__wrapper {
                  .v-messages__message {
                    color: #f70000;
                  }
                }
              }
            }
          }
        }
      }
      .submitBtn {
        color: #ffffff;
        background-color: #21a7e0;
      }
    }

    .staticContent {
      .heading {
        color: #000000;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 38px;
        margin-bottom: 16px;
      }

      .subHeading {
        color: #000000;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
}
</style>
